<template>
    <svg 
     width="15px" 
     height="15px" 
     class="loactionSvg"
     viewBox="0 0 11 16" 
     version="1.1" 
     xmlns="http://www.w3.org/2000/svg" 
     xmlns:xlink="http://www.w3.org/1999/xlink">
    
    <!-- Generator: sketchtool 63.1 (101010) - https://sketch.com -->
    <title>DBC65089-1096-4C43-8631-1EFF9E82AAE6</title>
    <desc>Created with sketchtool.</desc>
    <g id="Page-1" stroke="" stroke-width="1" fill="red" fill-rule="evenodd"></g>
    <g 
      id="Spec"
      transform="translate(-882.000000, -648.000000)" 
      fill="#fff" class="profilelocation"
      fill-rule="nonzero">
      <path d="M887.75,648.764268 C890.6525,648.764268 893,651.111768 893,654.014268 C893,657.951768 887.75,663.764268 887.75,663.764268 C887.75,663.764268 882.5,657.951768 882.5,654.014268 C882.5,651.111768 884.8475,648.764268 887.75,648.764268 Z M887.75,650.264268 C885.68,650.264268 884,651.944268 884,654.014268 C884,656.151768 886.19,659.421768 887.75,661.424268 C889.34,659.406768 891.5,656.174268 891.5,654.014268 C891.5,651.944268 889.82,650.264268 887.75,650.264268 Z M887.75,652.139268 C888.785534,652.139268 889.625,652.978734 889.625,654.014268 C889.625,655.049802 888.785534,655.889268 887.75,655.889268 C886.714466,655.889268 885.875,655.049802 885.875,654.014268 C885.875,652.978734 886.714466,652.139268 887.75,652.139268 Z"
       id="Location" stroke = "#2A2B75" class="profilelocation" ></path>
    </g>
    </svg>
    </template>
    <script>
    export default{
      name:"LocationSvg",
    }
    </script>
    <style lang="scss">
    
    @import "@/sass/_variables.scss";
    @import "@/sass/_components.scss";
    
    .loactionSvg{
      margin:5px;
    }
    .profilelocation{
      stroke:$clr-profile-location;
      fill:$clr-profile-location;
    }
    </style>